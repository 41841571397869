
import Vue, { PropType } from 'vue'
import { Block } from '@interfaces/BlockTypes'

export default Vue.extend({
  name: 'CommonEducationBrandsBlock',
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },

  data() {
    return {
      carouselSlides: 3,
    }
  },

  components: {},
  computed: {
    svgStyle() {
      const block: Block = this.block
      return block?.introIcons?.whiteLogo ? { filter: 'invert(1)' } : {}
    },
  },
  methods: {
    getCardNumber(index: number) {
      return Number(index) + 1
    },
  },
})
